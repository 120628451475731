<template>
  <svg
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0844 9.69643C14.7642 8.86599 15.1835 7.85322 15.2897 6.78527C15.6192 3.37902 12.9464 0.5 9.61696 0.5H0.714286C0.524845 0.5 0.343164 0.575255 0.209209 0.709209C0.0752549 0.843164 0 1.02485 0 1.21429V3.35714C0 3.54658 0.0752549 3.72826 0.209209 3.86222C0.343164 3.99617 0.524845 4.07143 0.714286 4.07143H2.13705V16.9286H0.714286C0.524845 16.9286 0.343164 17.0038 0.209209 17.1378C0.0752549 17.2717 0 17.4534 0 17.6429V19.7857C0 19.9752 0.0752549 20.1568 0.209209 20.2908C0.343164 20.4247 0.524845 20.5 0.714286 20.5H10.0589C13.2196 20.5 16.0473 18.1897 16.3536 15.0357C16.5652 12.8728 15.6219 10.9259 14.0844 9.69643ZM5.69911 4.07143H9.61696C10.1853 4.07143 10.7303 4.29719 11.1322 4.69906C11.5341 5.10092 11.7598 5.64596 11.7598 6.21429C11.7598 6.78261 11.5341 7.32765 11.1322 7.72952C10.7303 8.13138 10.1853 8.35714 9.61696 8.35714H5.69911V4.07143ZM9.61696 16.9286H5.69911V11.9286H9.61696C10.28 11.9286 10.9159 12.192 11.3847 12.6608C11.8536 13.1296 12.117 13.7655 12.117 14.4286C12.117 15.0916 11.8536 15.7275 11.3847 16.1963C10.9159 16.6652 10.28 16.9286 9.61696 16.9286Z"
      fill="currentColor"
    />
  </svg>
</template>
